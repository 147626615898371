import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import ConnectWallet from '../components/ConnectWallet';
import { useWalletContext } from '../contexts/WalletContext';
import { Link } from 'react-router-dom';

const FollowButton = (props) => {
    const [following, setFollowing] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const { walletConnected, setWalletConnected } = useWalletContext();
    const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);

    const { buttonWidth, producerId, onFollowStatusChange  } = props;

    useEffect(() => {
        const token = localStorage.getItem("access_token");
        if (currentUser && currentUser.following) {
            const producerIds = currentUser.following.map(followingProducer => followingProducer._id);
            setFollowing(producerIds.includes(producerId));
            localStorage.setItem(`following-${producerId}`, JSON.stringify(producerIds.includes(producerId)));
        } else if (token && localStorage.getItem(`following-${producerId}`)) {
            setFollowing(JSON.parse(localStorage.getItem(`following-${producerId}`)));
        }
    }, [currentUser, producerId]);

    const fetchCurrentUser = async () => {
        try {
          const token = localStorage.getItem("access_token");
          if (!token) return;
    
          const response = await fetch("https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/user", {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
              setCurrentUser(data.user);
        } catch (error) {
          console.error("Error fetching current user data:", error);
        }
      };
    
      useEffect(() => {
        fetchCurrentUser();
      }, []);

    const handleButtonClick = () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            handleFollow();
        }
    };

    const handleFollow = async () => {
        try {
            const token = localStorage.getItem("access_token");
            if (!token) return;

            const response = await fetch(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/follow/${producerId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    action: following ? 'unfollow' : 'follow',
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setCurrentUser(data.user);
                setFollowing(!following);
                localStorage.setItem(`following-${producerId}`, !following);
                onFollowStatusChange(!following); // Call the callback function
            } else {
                throw new Error('Error following or unfollowing user');
            }
        } catch (error) {
            console.error("Error following or unfollowing user:", error);
        }
    };

    const handleWalletConnect = useCallback(() => {
        setShowConnectWalletModal(false);
        setWalletConnected(true);
        console.log('handleWalletConnect called');
      }, [setWalletConnected]);
    
      const handleWalletDisconnect = useCallback(() => {
        setWalletConnected(false);
        setShowConnectWalletModal(false);
    
      }, [setWalletConnected]);

    return (
        <>
        <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wallet Connection Required"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please Connect your Wallet to like NFTs.
                    </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
                    </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setShowConnectWalletModal(true);
            }}
            color="primary"
            autoFocus
          >
            Connect
                          </Button>
        </DialogActions>
      </Dialog>
        <Button
            variant="contained"
            color={following ? "secondary" : "primary"}
            onClick={handleButtonClick}
            style={{ width: buttonWidth }}
        >
            {following ? "Following" : "Follow"}
        </Button>
        <Dialog
        open={showConnectWalletModal}
        onClose={() => setShowConnectWalletModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Connect Wallet to Mint NFT</DialogTitle>
        <DialogContent>
          <ConnectWallet
            onWalletConnected={handleWalletConnect}
            onWalletDisconnected={handleWalletDisconnect}
          />
        </DialogContent>
      </Dialog>
        </>
    );
};

export default FollowButton;
