import React, { useState, useCallback } from 'react';
import { Button, Typography, Snackbar, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useWalletContext } from '../contexts/WalletContext';
import axios from 'axios';

const SellOffer = ({ onOfferMade, onOfferFailed, nftId, nftName }) => {
  const { walletConnected, setWalletConnected } = useWalletContext();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [unsignedOfferSuccessful, setUnsignedOfferSuccessful] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [transactionRejected, setTransactionRejected] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const makeOffer = async (NFTokenID, offerPrice, expirationTime) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.post(
        'https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/sellOffer',
        { NFTokenID, offerPrice, expirationTime },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 201) {
        const payloadId = response.data.uuid.created.uuid;
        pollPayloadStatus(payloadId);

        setUnsignedOfferSuccessful(true);
        if (onOfferMade) {
          onOfferMade();
        }
      } else {
        setSnackbarMessage('Offer failed');
        setUnsignedOfferSuccessful(false);
        setSnackbarOpen(true);
        if (onOfferFailed) {
          onOfferFailed();
        }
      }
    } catch (error) {
      console.error('Error making offer:', error);
      setSnackbarMessage('Error making offer');
      setUnsignedOfferSuccessful(false);
      setSnackbarOpen(true);
      if (onOfferFailed) {
        onOfferFailed();
      }
    } finally {
      setLoading(false);
    }
  };

  async function pollPayloadStatus(uuid) {
    try {
    const response = await axios.get(
      `https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/auth/api/get-payload-status/${uuid}`
    );

    console.log('response: ', response);
    // Check the payload status
    if (response.data && response.data.meta) {
      if (response.data.meta.signed) {
        // Offer made successfully
        setSnackbarMessage('Offer made successfully');
        setUnsignedOfferSuccessful(false);
        setTransactionSuccessful(true);
        setSnackbarOpen(true);
        if (onOfferMade) {
          onOfferMade();
        }
      } else if (response.data.meta.cancelled) {
        // Offer rejected
        setSnackbarMessage('Offer rejected');
        setTransactionRejected(true);
        setUnsignedOfferSuccessful(false);
        setSnackbarOpen(true);
      } else if (response.data.meta.resolved && !response.data.meta.signed) {
        // User closed the app without signing the transaction
        setUnsignedOfferSuccessful(false);
        setTransactionRejected(true);
        setSnackbarMessage('User rejected the transaction');
        setSnackbarOpen(true);
      } else {
        // Keep polling
        setTimeout(() => pollPayloadStatus(uuid), 2000);
      }
    } else {
      // Keep polling
      setTimeout(() => pollPayloadStatus(uuid), 2000);
    }
  } catch (error) {
    console.error('Error polling payload status:', error);
  }
}  

  const [NFTokenID, setNFTokenID] = useState(nftId);
  const [offerPrice, setOfferPrice] = useState(0);
  const [expirationTime, setExpirationTime] = useState(new Date().toISOString());

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
     {unsignedOfferSuccessful ? (
        <React.Fragment>
          <CircularProgress size={24} />
          <Typography variant="body1" style={{ marginLeft: '10px' }}>
          Waiting for you to sign the transaction request with the XUMM app.
          </Typography>
        </React.Fragment>
      ) : ( transactionSuccessful ? (
          <>
        <CheckCircleIcon style={{ fontSize: 50, color: 'green' }} />
        <Typography variant="h4" gutterbottom="true">
            Sell Offer Created Successfully
          </Typography>
          </>
      ) : ( transactionRejected ? (
        <>
        <CancelIcon style={{ fontSize: 50, color: 'red' }} />
        <Typography variant="h4" gutterbottom="true">
            Sell Offer Rejected 
          </Typography>
          </>
      ) : (
      <React.Fragment>
        <Typography variant="h4" gutterbottom="true">
          Make a Sell Offer
        </Typography>
        <input
          value={nftName}
          onChange={e => setNFTokenID(e.target.value)}
          placeholder={nftName}
          disabled
        />
        <input
          value={offerPrice}
          onChange={e => setOfferPrice(e.target.value)}
          placeholder="Offer Price"
        />
        <input
          value={expirationTime}
          onChange={e => setExpirationTime(e.target.value)}
          placeholder="Expiration Time"
          type="datetime-local"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => makeOffer(NFTokenID, offerPrice, expirationTime)}
          disabled={loading || !walletConnected}
          >
          {loading ? <CircularProgress/> : 'Make Offer'}
        </Button>
      </React.Fragment>
      )))}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleSnackbarClose}>
              Close
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default SellOffer;
