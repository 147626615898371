import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, Grid, Card, Button, Box, Paper, useMediaQuery, TextField } from '@mui/material';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useWalletContext } from '../contexts/WalletContext';
import Sidebar from "../components/sidebar";
import defaultProfilePicture from "../images/defaultProfilePicture.png";
import { drawerWidth } from '../components/sidebar';
import { useTheme } from '@mui/material/styles';
import NftCard from '../components/NftCard';
import ConnectWallet from '../components/ConnectWallet';
import './NFTDetailPage.css';
import BuyOffer from '../components/BuyOffer';
import SellOffer from '../components/SellOffer';
import CancelOffer from '../components/CancelOffer';
import AcceptBuyOffer from '../components/AcceptBuyOffer';
import AcceptSellOffer from '../components/AcceptSellOffer';
import AudioContext from "../contexts/AudioContext";
import { CircularProgress } from '@mui/material';


const NFTDetailPage = () => {
    const { id } = useParams();
    const [nft, setNft] = useState(null);
    const [nfts, setNfts] = useState([]);
    const { setNftAudioUrls } = useContext(AudioContext);
    const [currentUser, setCurrentUser] = useState(null);
    const [userOwnsNFT, setUserOwnsNFT] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const closedDrawerWidth = isSmallScreen ? theme.spacing(7) : theme.spacing(8);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const { walletConnected, setWalletConnected, token } = useWalletContext();

    const [connectWalletOpen, setConnectWalletOpen] = React.useState(false);
    const [createBuyOfferOpen, setCreateBuyOfferOpen] = React.useState(false);
    const [createSellOfferOpen, setCreateSellOfferOpen] = React.useState(false);
    const [createCancelOfferOpen, setCreateCancelOfferOpen] = React.useState(false);
    const [createAcceptOfferOpen, setCreateAcceptOfferOpen] = React.useState(false);
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const [selectedOfferPrice, setSelectedOfferPrice] = useState(null);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);

    const [showConnectWallet, setShowConnectWallet] = useState(false);
    const [walletAddress, setWalletAddress] = useState(null);
    const [nftOwner, setNftOwner] = useState(null);
    const [buyersData, setBuyersData] = useState([]);
    const [sellersData, setSellersData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
    const [openBuyOfferDialog, setOpenBuyOfferDialog] = useState(false);
    const [openSellOfferDialog, setOpenSellOfferDialog] = useState(false);

    useEffect(() => {
        const fetchData = async (token) => {
            try {
                // Fetch current user
                if (token) {
                    const responseUser = await fetch("https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/user", {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    const userData = await responseUser.json();

                    setCurrentUser(userData.user);
                    if (userData.user.classicAddress != null) {
                        setWalletConnected(true);
                        setWalletAddress(userData.user.classicAddress);
                    }

                    // Fetch NFT by ID
                    const responseNft = await fetch(
                        `https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/id/${id}`
                    );
                    const dataNft = await responseNft.json();
                    setNft(dataNft);
                    // Check if the user owns this NFT if the user is logged in, by retrieving all nfts of wallet address.
                    if (userData && userData.user && userData.user.classicAddress) {
                        const responseWallet = await fetch(
                            `https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/owner/${userData.user.classicAddress}`
                        );
                        const dataWallet = await responseWallet.json();
                        const userOwnsNFT = dataWallet.data?.nfts?.some(nft => nft.NFTokenID === dataNft.NFTokenID);
                        setUserOwnsNFT(userOwnsNFT);
                    }
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const jwtToken = localStorage.getItem("access_token");
        fetchData(jwtToken);
    }, [id, walletConnected, userOwnsNFT, walletAddress, setWalletConnected, token]);


    const shortenId = (id) => {
        if (!id) return '';
        const start = id.slice(0, 6);
        const end = id.slice(-4);
        return `${start}...${end}`;
    };

    const paperStyle = {
        padding: '8px',
        borderRadius: '4px',
        borderColor: 'rgba(0, 200, 0, 0.3)',  // green with 20% opacity
        borderStyle: 'solid',
        borderWidth: '1px',
    };


    const noOffersStyle = {
        fontStyle: 'italic',
        color: 'rgba(0, 0, 0, 0.6)',
    };

    const refetchNftData = async () => {
        try {
            const response = await fetch(
                `https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/id/${id}`
            );
            const data = await response.json();
            setNft(data);
        } catch (error) {
            console.error('Error fetching NFT data:', error);
        }
    };


    useEffect(() => {
        const fetchNftById = async () => {
            try {
                const response = await fetch(
                    `https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/id/${id}`
                );
                const data = await response.json();
                setNft(data);
            } catch (error) {
                console.error('Error fetching NFT data:', error);
            }
        };

        refetchNftData();
        fetchNftById();
    }, [id]);

    const handleConnectWalletOpen = () => {
        setConnectWalletOpen(true);
    };

    const handleConnectWalletClose = () => {
        setConnectWalletOpen(false);
    };

    const handleCreateBuyOfferOpen = () => {
        // Implement the logic for creating a buy or sell offer
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            setCreateBuyOfferOpen(true);
        }
    };

    const handleCreateBuyOfferClose = () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            setCreateBuyOfferOpen(false);
            refetchNftData();
        }
    };

    const handleCreateSellOfferOpen = () => {
        // Implement the logic for creating a buy or sell offer
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            setCreateSellOfferOpen(true);
        }
    };

    const handleCreateSellOfferClose = () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            setCreateSellOfferOpen(false);
            refetchNftData();
        }
    };

    const handleCreateCancelOfferOpen = () => {
        // Implement the logic for creating a buy or sell offer
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            setCreateCancelOfferOpen(true);
        }
    };

    const handleCreateCancelOfferClose = () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            setCreateCancelOfferOpen(false);
            refetchNftData();
        }
    };

    const handleCreateAcceptOfferOpen = () => {
        // Implement the logic for creating a buy or sell offer
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            setCreateAcceptOfferOpen(true);
        }
    };

    const handleCreateAcceptOfferClose = () => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            setOpenDialog(true);
        } else {
            setCreateAcceptOfferOpen(false);
            refetchNftData();
        }
    };

    useEffect(() => {
        if (nft && nft._id) {
            fetchComments();
        }
    }, [nft]);

    const fetchComments = async () => {
        try {
            const response = await fetch(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/${nft._id}/getNFTComments`);
            const data = await response.json();

            const commentsWithUserDetails = await Promise.all(
                data.comments.map(async (comment) => {
                    const userResponse = await fetch(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/${comment.user}`);
                    const userData = await userResponse.json();
                    return {
                        ...comment,
                        username: userData.user.username,
                        profilePicture: userData.user.profilePicture,
                    };
                })
            );

            setComments(commentsWithUserDetails);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCommentSubmit = async () => {
        const token = localStorage.getItem('access_token');

        // Make the API call to add the comment
        if (token) {
            try {
                const response = await fetch(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/${nft._id}/comment`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        comment: comment
                    })
                });

                if (response.ok) {
                    const data = await response.json();
                    // Update the comments state by fetching the updated comments
                    fetchComments();
                    // Clear the comment input
                    setComment('');
                } else {
                    console.error('Failed to submit comment');
                }
            } catch (error) {
                console.error('Error submitting comment:', error);
            }
        }
    };

    const handleDeleteComment = (commentId) => {
        // Make a DELETE request to your API endpoint
        const token = localStorage.getItem("access_token");
        if (token) {
            console.log('Deleting comment:', commentId);

            fetch(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/${nft._id}/comment/${commentId}`, {
                method: 'DELETE',
                // Add any necessary headers or authentication tokens
                headers: { Authorization: `Bearer ${token}` },
            })
                .then((response) => {
                    console.log('Response:', response);
                    if (response.ok) {
                        // Comment deleted successfully, update the comments state
                        const updatedComments = comments.filter((comment) => comment._id !== commentId);
                        setComments(updatedComments);
                    } else {
                        console.error('Error:', response.status);
                    }
                })
                .catch((error) => {
                    console.error('Fetch Error:', error); // Log the fetch error
                });
        }
    };


    useEffect(() => {
        const fetchOwner = async () => {
            const getOwner = await fetch(
                `https://test-api.xrpldata.com/api/v1/xls20-nfts/nft/${nft?.NFTokenID}`
            );
            const owner = await getOwner.json();
            setNftOwner(owner?.data?.nft?.Owner);
        }
        fetchOwner();
    }, [walletConnected, walletAddress, nftOwner, nft?.NFTokenID]);

    useEffect(() => {
        const fetchNfts = async () => {
            try {
                let url = "https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/NFTs/getAllNFTs";
                const response = await fetch(url);
                const data = await response.json();

                setNfts(data);

                // Update the nftAudioUrls array in the AudioContext
                const audioUrls = data.map(nft => nft);
                setNftAudioUrls(audioUrls);
            } catch (error) {
                console.error("Error fetching NFT data:", error);
            }
        };

        fetchNfts();
    }, []);

    // Retrieve BuyOfferer's User data
    const getBuyOfferIssuerData = async () => {
        const buyersData = []; // To store data of all buyers

        if (nft?.buyOffers && typeof nft.buyOffers[Symbol.iterator] === 'function') {
            for (let offer of nft?.buyOffers) {
                const offerIssuer = offer.buyer._id;

                const response = await fetch(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/${offerIssuer}`);
                const data = await response.json();
                buyersData.push(data.user);
            }
        } else {
            console.log('nft.buyOffers is undefined or not iterable');
        }

        setBuyersData(buyersData); // Assuming you've defined buyersData state with useState
    }

    // Retrieve SellOfferIssuer's User data
    const getSellOfferIssuerData = async () => {
        const sellersData = []; // To store data of all sellers

        if (nft?.sellOffers && typeof nft.sellOffers[Symbol.iterator] === 'function') {
            for (let offer of nft?.sellOffers) {
                const offerIssuer = offer.seller._id;
                const response = await fetch(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/${offerIssuer}`);
                const data = await response.json();
                sellersData.push(data.user);
            }
        } else {
            console.log('nft.buyOffers is undefined or not iterable');
        }
        setSellersData(sellersData); // Update the sellersData state with the retrieved data
    }

    useEffect(() => {
        getBuyOfferIssuerData();
        getSellOfferIssuerData();
    }, [nft]); // Added nft to dependency array

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Log in required"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please log in or register to connect wallet.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Link to="/register">
                        <Button
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            color="primary"
                            autoFocus
                        >
                            Register
                        </Button>
                    </Link>
                    <Link to="/login">
                        <Button
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            color="primary"
                        >
                            Log in
                        </Button>
                    </Link>
                </DialogActions>
            </Dialog>
            <Box sx={{ background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)', 
            overflow: 'hidden', 
            }}>
            <Container sx={{ marginTop: "64px", minHeight: "120vh", background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)' }}>
                <Box
                    sx={{
                        display: 'flex',
                        marginLeft: drawerOpen ? drawerWidth : closedDrawerWidth,
                        transition: theme.transitions.create('margin', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)'
                    }}
                >
                    <Sidebar hideappbar="true" initialDrawerOpen={drawerOpen} />
                    <Box>
                        {nft ? (
                            <Card
                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '4px',
                                    padding: '16px',
                                    marginTop: '12px',
                                    marginLeft: '12px',
                                    marginRight: '12px',
                                }}
                            >
                                <Box>
                                    <Typography variant="h5" component="h1" gutterbottom="true">
                                        {nft.Name}
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            {nft && <NftCard nft={nft} />}
                                            {walletConnected ? (
                                                userOwnsNFT ? (
                                                    <>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                marginLeft: 1,
                                                                borderRadius: 1,
                                                                paddingX: 1,
                                                                paddingY: 0.5,
                                                                backgroundColor: 'grey.300',
                                                                width: '50%',
                                                            }}
                                                        ><Typography>You own this NFT</Typography></Box>
                                                        <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                            &nbsp;&nbsp;&nbsp;
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                            <b> Current Wallet Connected: </b> {walletAddress}
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleCreateSellOfferOpen}
                                                            sx={{ marginTop: '8px', fontSize: '0.75rem' }}
                                                        >
                                                            Create Sell Offer
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                            <b> Current Wallet Connected: </b> {walletAddress}
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleCreateBuyOfferOpen}
                                                            sx={{ marginTop: '8px', fontSize: '0.75rem' }}
                                                        >
                                                            Create Buy Offer
                                                        </Button>
                                                    </>
                                                )
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleConnectWalletOpen}
                                                    sx={{ marginTop: '8px', fontSize: '0.75rem' }}
                                                >
                                                    Connect Wallet
                                                </Button>)}

                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b>Owner: </b> <a href={`https://test.bithomp.com/explorer/${nftOwner}`} // Use the testnet Bithomp URL
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                ><u>{shortenId(nftOwner)}</u></a>
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b>Producer: </b> {nft.Producer}
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b>  Genre: </b> {nft.Genre}
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b>  NFTokenId: </b> <a
                                                    href={`https://test.bithomp.com/explorer/${nft.NFTokenID}`} // Use the testnet Bithomp URL
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                ><u>{shortenId(nft.NFTokenID)}</u></a>
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b> License Type: </b> {nft.LicenseType}
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b> License StartDate: </b> {nft.LicenseStartDate}
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b> License EndDate: </b> {nft.LicenseEndDate}
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b> Territory: </b> {nft.Territory}
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                <b> Rights Included: </b> {nft.RightsIncluded}
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                &nbsp;&nbsp;&nbsp;
                                            </Typography>
                                            <Typography variant="subtitle2" component="h2" gutterbottom="true">
                                                View on Bithomp <a
                                                    href={`https://test.bithomp.com/explorer/${nft.NFTokenID}`} // Use the testnet Bithomp URL
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ color: 'blue' }} // Apply inline styling for the blue color
                                                >
                                                    XRP Explorer
                                                </a>
                                            </Typography>

                                            {/* Marks beginning of offers & comments section */}
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Box mt={2}>
                                                    <Typography variant="subtitle1" component="h2" gutterbottom="true">
                                                        Buy Offers
                                                    </Typography>
                                                    <Paper elevation={2} sx={paperStyle}>
                                                        {nft.buyOffers.length === 0 ? (
                                                            <Typography variant="body2" component="p" sx={noOffersStyle}>
                                                                No offers found
                                                            </Typography>
                                                        ) : (
                                                            <List sx={{ maxHeight: '200px', overflow: 'auto' }}>
                                                                {nft.buyOffers.map((offer, index) => (
                                                                    <ListItem
                                                                        key={index}
                                                                    >
                                                                        <ListItemAvatar>
                                                                            {buyersData[index]?.profilePicture ? (
                                                                                <Avatar src={buyersData[index].profilePicture} />
                                                                            ) : (
                                                                                <Avatar>{defaultProfilePicture}</Avatar>
                                                                            )}
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            className="list-item-text"
                                                                            primary={buyersData[index]?.username}
                                                                            secondary={`${offer.price} XRP`}
                                                                        />
                                                                        <ListItemSecondaryAction>
                                                                            {walletConnected && currentUser && userOwnsNFT ? (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    onClick={() => {
                                                                                        setSelectedOfferId(offer.offerId);
                                                                                        setSelectedOfferPrice(offer.price);
                                                                                        handleCreateAcceptOfferOpen();
                                                                                    }}
                                                                                >
                                                                                    Accept
                                                                                </Button>
                                                                            ) : null}
                                                                        </ListItemSecondaryAction>
                                                                        <ListItemSecondaryAction>
                                                                            {walletConnected && currentUser && offer.buyer._id === currentUser._id ? (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="danger"
                                                                                    size="small"
                                                                                    style={{ color: "white" }}
                                                                                    onClick={() => {
                                                                                        setSelectedOfferId(offer.offerId);
                                                                                        handleCreateCancelOfferOpen();
                                                                                    }}                                                                                    >
                                                                                    Cancel
                                                                                </Button>
                                                                            ) : null}
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        )}
                                                    </Paper>
                                                </Box>
                                                <Box mt={2}>
                                                    <Typography variant="subtitle1" component="h2" gutterbottom="true">
                                                        Sell Offers
                                                    </Typography>
                                                    <Paper elevation={2} sx={paperStyle}>
                                                        {nft.sellOffers.length === 0 ? (
                                                            <Typography variant="body2" component="p" sx={noOffersStyle}>
                                                                No offers found
                                                            </Typography>
                                                        ) : (
                                                            <List sx={{ maxHeight: '200px', overflow: 'auto' }}>
                                                                {nft.sellOffers.map((offer, index) => (
                                                                    <ListItem key={index}>
                                                                        <ListItemAvatar>
                                                                            {sellersData[index]?.profilePicture ? (
                                                                                <Avatar src={sellersData[index].profilePicture} />
                                                                            ) : (
                                                                                <Avatar>{defaultProfilePicture}</Avatar>
                                                                            )}
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            className="list-item-text"
                                                                            primary={sellersData[index]?.username}
                                                                            secondary={`${offer.price} XRP`}
                                                                        />
                                                                        <ListItemSecondaryAction>
                                                                            {walletConnected && currentUser && !userOwnsNFT ? (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    onClick={() => {
                                                                                        setSelectedOfferId(offer.offerId);
                                                                                        handleCreateAcceptOfferOpen();
                                                                                    }}
                                                                                >
                                                                                    Accept
                                                                                </Button>
                                                                            ) : null}
                                                                        </ListItemSecondaryAction>
                                                                        <ListItemSecondaryAction>
                                                                            {walletConnected && currentUser && offer.seller._id === currentUser._id ? (
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="danger"
                                                                                    size="small"
                                                                                    style={{ color: "white" }}
                                                                                    onClick={() => {
                                                                                        setSelectedOfferId(offer.offerId);
                                                                                        handleCreateCancelOfferOpen();
                                                                                    }}                                                                                    >
                                                                                    Cancel
                                                                                </Button>
                                                                            ) : null}
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        )}
                                                    </Paper>
                                                </Box>
                                                <Box mt={2}>
                                                    <Typography variant="subtitle1" component="h2" gutterBottom={true}>
                                                        Comments
                                                    </Typography>
                                                    <Paper elevation={2} sx={paperStyle}>
                                                        {comments.length === 0 ? (
                                                            <Typography variant="body2" component="p" sx={noOffersStyle}>
                                                                Be the first to leave a comment!
                                                            </Typography>
                                                        ) : (
                                                            // Render comments here
                                                            <List sx={{ maxHeight: '200px', overflow: 'auto' }}>
                                                                {comments.map((comment, index) => (
                                                                    <ListItem key={index}>
                                                                        <ListItemAvatar>
                                                                            {comment.profilePicture ? (
                                                                                <Avatar src={comment.profilePicture} />
                                                                            ) : (
                                                                                <Avatar>{defaultProfilePicture}</Avatar>
                                                                            )}
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={comment.username}
                                                                            secondary={comment.comment} // Show username as secondary text
                                                                        />
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() => handleDeleteComment(comment?._id)}
                                                                        >
                                                                            {walletConnected && currentUser?._id == comment.user ? (
                                                                                <DeleteIcon />) : null}
                                                                        </IconButton>
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        )}
                                                        {/* Add the comment input */}
                                                        <TextField
                                                            label={walletConnected && currentUser ? "Add a comment" : "Connect Wallet to leave a Comment"}
                                                            multiline
                                                            rows={4}
                                                            value={comment}
                                                            onChange={(event) => setComment(event.target.value)}
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                            disabled={!walletConnected || !currentUser}
                                                        />
                                                        {/* Add the submit button */}
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => walletConnected && currentUser ? handleCommentSubmit() : handleConnectWalletOpen()}
                                                            disabled={!comment && walletConnected && currentUser} // Disable the button if 'comment' is empty and wallet is connected with a current user
                                                            >
                                                            {walletConnected && currentUser ? "Submit Comment" : "Add Comment"}
                                                        </Button>

                                                    </Paper>
                                                </Box>



                                            </Grid>
                                            {/* marks end of offers & comments section */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        ) : (                
                            <CircularProgress />
                        )}
                    </Box>
                    {/* Connect Wallet Dialog */}
                    <Dialog open={connectWalletOpen} onClose={handleConnectWalletClose}>
                        <DialogContent>
                            <ConnectWallet onWalletConnected={() => { setShowConnectWallet(false); }} />
                        </DialogContent>
                    </Dialog>
                    {/* Create Buy Offer Dialog */}
                    <Dialog open={createBuyOfferOpen} onClose={handleCreateBuyOfferClose}>
                        <DialogContent>
                            <BuyOffer nftId={nft?.NFTokenID} nftName={nft?.Name} />
                        </DialogContent>
                    </Dialog>
                    {/* Create Sell Offer Dialog */}
                    <Dialog open={createSellOfferOpen} onClose={handleCreateSellOfferClose}>
                        <DialogContent>
                            <SellOffer nftId={nft?.NFTokenID} nftName={nft?.Name} />
                        </DialogContent>
                    </Dialog>
                    {/* Create Cancel Offer Dialog */}
                    <Dialog open={createCancelOfferOpen} onClose={handleCreateCancelOfferClose}>
                        <DialogContent>
                            <CancelOffer nftId={nft?.NFTokenID} nftName={nft?.Name} offer={selectedOfferId} />
                        </DialogContent>
                    </Dialog>
                    {/* Create Accept Buy Offer Dialog */}
                    <Dialog open={createAcceptOfferOpen} onClose={handleCreateAcceptOfferClose}>
                        <DialogContent>
                            <AcceptBuyOffer nftId={nft?.NFTokenID} nftName={nft?.Name} offer={selectedOfferId} price={selectedOfferPrice} />
                        </DialogContent>
                    </Dialog>
                    {/* Create Accept Sell Offer Dialog */}
                    <Dialog open={createAcceptOfferOpen} onClose={handleCreateAcceptOfferClose}>
                        <DialogContent>
                            <AcceptSellOffer nftId={nft?.NFTokenID} nftName={nft?.Name} offer={selectedOfferId} price={selectedOfferPrice} />
                        </DialogContent>
                    </Dialog>
                </Box>
            </Container>
            </Box>
        </>
    );
};

export default NFTDetailPage;