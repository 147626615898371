import React, { useState, useEffect, useCallback, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, InputLabel, FormControl, Button, Box, Card, CardContent, useTheme, useMediaQuery, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { drawerWidth } from '../components/sidebar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { Grid } from '@mui/material';
import Sidebar from "../components/sidebar";
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import ConnectWallet from '../components/ConnectWallet';
import { useWalletContext } from '../contexts/WalletContext';
import { Backdrop, CircularProgress } from '@mui/material';
import AudioContext from "../contexts/AudioContext";

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1, 0),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    width: '490px',
    maxWidth: 'md',
    alignItems: 'center',
    margin: theme.spacing(2, 'auto'), // Add margin to center the card
    padding: theme.spacing(2), // Add padding
}));

const MintNFT = () => {
    const navigate = useNavigate();
    const [nfts, setNfts] = useState([]);
    const { setNftAudioUrls } = useContext(AudioContext);

    const [formData, setFormData] = useState({
        Name: '',
        Producer: null,
        Genre: '',
        LicenseType: '',
        LicenseStartDate: null,
        LicenseEndDate: null,
        Territory: '',
        RightsIncluded: '',
        Royalties: '',
        isForSale: false,
        audioFile: null,
        imageFile: null,
    });

    const genreOptions = [
        'Rock',
        'Pop',
        'Hip-Hop',
        'Electronic',
        'Country',
        'Jazz',
        'Blues',
        'Reggae',
        'Classical',
        'Folk',
        'World',
        'Trap',
    ];

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const closedDrawerWidth = isSmallScreen ? theme.spacing(7) : theme.spacing(8);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { walletConnected, setWalletConnected } = useWalletContext();
    const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogInfo, setDialogInfo] = useState({
        open: false,
        message: "",
    });

    // eslint-disable-next-line 
    const fetchUsernameAndWallet = async () => {
        try {
            const token = localStorage.getItem('access_token');
            if (token) {
                const response = await axios.get('https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/user', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const username = response.data.user.username;

                setFormData((prevState) => ({
                    ...prevState,
                    Producer: username,
                }));
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setDrawerOpen(false);

        fetchUsernameAndWallet();
    }, [walletConnected, setWalletConnected]);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            setWalletConnected(true);
        }
    }, [formData.Producer, setWalletConnected]);


    useEffect(() => {
        if (walletConnected === false) {
            setShowConnectWalletModal(true);
        }
    }, [walletConnected]);

    const handleWalletConnect = useCallback(() => {
        setShowConnectWalletModal(false);
        setWalletConnected(true);
    }, []);

    const handleWalletDisconnect = useCallback(() => {
        setWalletConnected(false);
        setShowConnectWalletModal(false);

    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const form = new FormData();
        Object.keys(formData).forEach((key) => {
            form.append(key, formData[key]);
        });

        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post('https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/mint', form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
            setDialogInfo({ open: true, message: "NFT created successfully" });
            setNftAudioUrls((prevState) => [...prevState, formData.audioFile]);
        } catch (error) {
            console.error(error);
            setDialogInfo({ open: true, message: "Error creating NFT" });
        }
        setIsLoading(false);
    };

    const handleCloseDialog = () => {
        setDialogInfo({ open: false, message: "" });
        navigate('/');
    };
        
    return (

        <Box sx={{ display: 'flex', background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)', minHeight: '170vh' }}>
            <Sidebar hideappbar="true" initialDrawerOpen={!drawerOpen} />
            {walletConnected ? (
                <div
                    className="content-area flex-1 pt-8"
                    style={{
                        marginLeft: drawerOpen
                            ? `calc(${drawerWidth}px - 64px)`
                            : `calc(${closedDrawerWidth}px - 48px)`,
                        transition: theme.transitions.create('margin-left', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    }}
                >
                    <StyledCard sx={{background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)'}}>
                        <CardContent>
                            <h2 className="text-2xl font-semibold mb-6">Mint NFT</h2>
                            <form onSubmit={handleSubmit} className="w-full max-w-md">
                                {[
                                    ['Name', 'Name', 'text'],
                                    ['Territory', 'Territory', 'text'],
                                    ['RightsIncluded', 'Rights Included', 'text'],
                                ].map(([name, label, type]) => (
                                    <TextField
                                        key={name}
                                        fullWidth
                                        margin="normal"
                                        label={label}
                                        type={type}
                                        name={name}
                                        value={formData[name]}
                                        onChange={handleChange}
                                        required
                                        variant="outlined"
                                    />
                                ))}
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Producer"
                                    type="text"
                                    name="Producer"
                                    value={formData.Producer || ''} // Use a fallback value to prevent rendering 'null' in the input field
                                    onChange={handleChange}
                                    required
                                    variant="outlined"
                                    disabled
                                />
                                <FormControl fullWidth margin="normal" variant="outlined">
                                    <InputLabel htmlFor="Genre">Genre</InputLabel>
                                    <Select
                                        label="Genre"
                                        name="Genre"
                                        value={formData.Genre}
                                        onChange={handleChange}
                                        required
                                    >
                                        {genreOptions.map((genre) => (
                                            <MenuItem key={genre} value={genre}>
                                                {genre}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            margin="normal"
                                            label="License Type"
                                            type="text"
                                            name="LicenseType"
                                            value={formData.LicenseType}
                                            onChange={handleChange}
                                            required
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                fullWidth
                                                margin="normal"
                                                label="License Start Date"
                                                value={formData.LicenseStartDate}
                                                onChange={(date) => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        LicenseStartDate: date,
                                                    }));
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                required
                                                sx={{ width: '100%' }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                fullWidth
                                                margin="normal"
                                                label="License End Date"
                                                value={formData.LicenseEndDate}
                                                onChange={(date) => {
                                                    setFormData((prevState) => ({
                                                        ...prevState,
                                                        LicenseEndDate: date,
                                                    }));
                                                }}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                required
                                                sx={{ width: '100%' }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>


                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Royalties"
                                    type="number"
                                    name="Royalties"
                                    value={formData.Royalties}
                                    onChange={handleChange}
                                    required
                                    inputProps={{ min: '0', max: '50', step: '0.01' }}
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Audio File"
                                    type="file"
                                    name="audioFile"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ accept: '.mp3,.wav,.ogg' }}
                                    onChange={handleFileChange}
                                    required
                                    variant="outlined"
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Image File"
                                    type="file"
                                    name="imageFile"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ accept: '.jpg,.jpeg,.png,.gif' }}
                                    onChange={handleFileChange}
                                    required
                                    variant="outlined"
                                />
                                <StyledButton type="submit" fullWidth variant="contained">
                                    Mint NFT
        </StyledButton>
                            </form>
                        </CardContent>
                    </StyledCard>
                </div>
            ) : (
                    <Dialog
                        open={showConnectWalletModal}
                        onClose={() => setShowConnectWalletModal(false)}
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>Connect Wallet to Mint NFT</DialogTitle>
                        <DialogContent>
                            <ConnectWallet
                                onWalletConnected={handleWalletConnect}
                                onWalletDisconnected={handleWalletDisconnect}
                            />
                        </DialogContent>
                    </Dialog>
                )}
            <Dialog open={dialogInfo.open} onClose={handleCloseDialog}>
                <DialogTitle>{dialogInfo.message}</DialogTitle>
                <DialogContent>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
    </Button>
                </DialogContent>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
                <Typography variant="h6" mt={2}>
                    Please Sign the Transaction on your XUMM App
            </Typography>
            </Backdrop>

        </Box>

    );
};

export default MintNFT;