import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Grid, Card, CardContent, CardMedia, Typography, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import AppBar from "@mui/material/AppBar";
import Sidebar from "../components/sidebar";
import Divider from '@mui/material/Divider';
import { useMediaQuery } from '@mui/material';
import { drawerWidth } from '../components/sidebar';
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { InView } from 'react-intersection-observer';
import NftCard from "../components/NftCard";
import "./marketplace.css"
import defaultProfilePicture from "../images/defaultProfilePicture.png";
import ProducerCard from '../components/producerCard';
import AudioContext from "../contexts/AudioContext";


const MarketplacePage = () => {
  const [nfts, setNfts] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);
  const [userId, setUserId] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [producers, setProducers] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const closedDrawerWidth = isSmallScreen ? theme.spacing(7) : theme.spacing(8);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { setNftAudioUrls } = useContext(AudioContext);

  const fetchCurrentUser = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) return;

      if (token) {
        const response = await fetch("https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/user", {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();

        setCurrentUser(data.user);
      }
    } catch (error) {
      console.error("Error fetching current user data:", error);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);


  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getIndicatorStyle = (selectedIndex) => {
    const baseStyle = {
      width: '75%', // Adjust the width of the indicator
    };

    switch (selectedIndex) {
      case 0:
        return { ...baseStyle, marginLeft: '25%', };
      case 1:
        return { ...baseStyle, marginLeft: '75%' };
      case 2:
        return { ...baseStyle, marginLeft: '125%' };
      case 3:
        return { ...baseStyle, marginLeft: '175%' };
      default:
        return baseStyle;
    }
  };

  const groupNftsByGenre = (nfts) => {
    const groupedNfts = {};

    nfts.forEach((nft) => {
      if (!nft.Genre) {
        return;
      }

      const genreKey = nft.Genre.toLowerCase();

      if (!groupedNfts[genreKey]) {
        groupedNfts[genreKey] = {
          name: nft.Genre,
          nfts: [],
        };
      }

      groupedNfts[genreKey].nfts.push(nft);
    });

    return groupedNfts;
  };

  const sortByNewest = (nft1, nft2) => {
    if (!nft1.DateCreated?.createdAt && !nft2.DateCreated?.createdAt) {
      return 0;
    }
    if (!nft1.DateCreated?.createdAt) {
      return 1;
    }
    if (!nft2.DateCreated?.createdAt) {
      return -1;
    }
    const date1 = new Date(nft1.DateCreated.createdAt);
    const date2 = new Date(nft2.DateCreated.createdAt);
    return date2 - date1;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = "https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/NFTs/getAllNFTs";
        let setData = setNfts;

        if (tabIndex === 1) {
          url = "https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/producers";
          setData = setProducers;
        }

        const response = await fetch(url);
        const data = await response.json();

        setData(data);

        if (tabIndex !== 1) {
          // Update the nftAudioUrls array in the AudioContext
          const audioUrls = data.map(nft => nft);
          setNftAudioUrls(audioUrls);
          console.log('mpps audioUrls have been set: ', audioUrls);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [tabIndex]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setUserId(decodedToken._id);
    }
  }, []);

  const filterNftsBySearch = (nft) => {
    if (!searchInput) return true;
    const searchString = searchInput.toLowerCase();
    const nameMatch = nft.Name ? nft.Name.toLowerCase().includes(searchString) : false;
    const producerMatch = nft.Producer ? nft.Producer.toLowerCase().includes(searchString) : false;
    return nameMatch || producerMatch;
  };

  const filterProducersBySearch = (producer) => {
    if (!searchInput) return true;
    const searchString = searchInput.toLowerCase();
    return producer.username.toLowerCase().includes(searchString);
  };

  const filterGenresBySearch = (genreData) => {
    if (!searchInput) return true;
    const searchString = searchInput.toLowerCase();
    const genreNameMatch = genreData.name.toLowerCase().includes(searchString);
    const nftsMatch = genreData.nfts.some(filterNftsBySearch);
    return genreNameMatch || nftsMatch;
  };

  return (
    <Box
      sx={{
        display: "flex",
        background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)', // Linear gradient from left (#D8D8D8) to right (#F0F0F0)
        minHeight: "100vh",
        "& main": {      // Add this rule
          margin: 0,
          padding: 0,
        },
      }}
    >
      <Sidebar initialDrawerOpen={drawerOpen} searchInput={searchInput} setSearchInput={setSearchInput} />
      <Box
        className="content-area flex-1 pt-8"
        style={{
          marginLeft: drawerOpen
            ? `calc(${drawerWidth}px - 64px)`
            : `calc(${closedDrawerWidth}px - 48px)`, // Adjust the marginLeft directly
          transition: theme.transitions.create("margin-left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
        pl={4} // Add padding-left to the content area
      >

        <Box sx={{ marginTop: "40px" }}>
          <AppBar position="static" color="inherit" elevation={1} sx={{ boxShadow: "none", background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)', }}>
            <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" TabIndicatorProps={{
              style: {
                style: getIndicatorStyle(tabIndex),
              },
            }}>
              <Tab label="Beats" />
              <Tab label="Producers" />
              <Tab label="Genres" />
              <Tab label="New Releases" />
            </Tabs>
          </AppBar>
          <Divider style={{ marginLeft: "16px", marginRight: "16px" }} />
          <div
            style={{
              maxHeight: "calc(100vh - 64px - 40px - 48px)", // Adjust the maxHeight accordingly
              overflowY: "scroll",
            }}
          >
            <div className="flex flex-wrap justify-center items-center" style={{ padding: "0 4px" }}>
              {tabIndex === 1
                ? (
                  <Grid container spacing={4} sx={{ marginTop: '-16px' }}>
                    {producers.filter(filterProducersBySearch).map((producer) => (
                      <ProducerCard key={producer._id} producer={producer} currentUser={currentUser} setCurrentUser={setCurrentUser} />
                    ))}
                  </Grid>
                )
                : tabIndex === 2
                  ? Object.entries(groupNftsByGenre(nfts)).filter(([genreKey, genreData]) => filterGenresBySearch(genreData)).map(([genreKey, genreData]) => (
                    <React.Fragment key={genreKey}>
                      <Typography
                        variant="h4"
                        component="h2"
                        gutterbottom="true"
                        align="center"
                        sx={{
                          fontWeight: 'bold',
                          marginTop: 2,
                          marginBottom: 2,
                          textStroke: '2px #076425',
                          WebkitTextStroke: '1px #78BC61',
                          textFillColor: '076425',
                        }}
                      >
                        {genreData.name}
                      </Typography>


                      <Grid container spacing={4} justifyContent="center">
                        {genreData.nfts.map((nft) => (
                          <Grid item key={nft._id} xs={12} sm={6} md={4} lg={3} xl={2} sx={{ width: { xs: '100%', sm: '50%', md: '33%', lg: '25%', xl: '16.666%' } }}>
                            <NftCard nft={nft} nfts={genreData.nfts} nftIndex={genreData.nfts.findIndex((item) => item._id === nft._id)} />
                          </Grid>
                        ))}
                      </Grid>
                    </React.Fragment>
                  ))
                  : nfts
                    .filter((nft) => {
                      switch (tabIndex) {
                        case 0:
                          // Show all beats
                          return true;
                        case 2:
                          // Show beats by genre
                          return nft.Genre === 'Genre Name';
                        case 3:
                          // Show new releases
                          return nft.DateCreated?.createdAt; // Filter NFTs with a createdAt attribute
                        default:
                          return true;
                      }
                    })
                    .filter(filterNftsBySearch)
                    .sort((nft1, nft2) => tabIndex === 0 ? 0 : sortByNewest(nft1, nft2)) // Use sortByNewest for all tabs except "Beats" tab
                    .filter(nft => nft).map((nft, index) => {
                      return (<InView triggerOnce key={nft._id}>
                        {({ inView, ref }) => (
                          <div ref={ref}>
                            {inView && <NftCard nft={nft} nfts={nfts} nftIndex={index} />}
                          </div>
                        )}
                      </InView>);

                    })}
            </div>
          </div>
        </Box >
      </Box >
    </Box >
  );
};

export default MarketplacePage;