import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { useRoutes } from 'react-router';
import ProfilePage from './pages/ProfilePage';
import MintNFTPage from './pages/MintNFTPage';
import NFTDetailPage from './pages/NFTDetailPage';
import MarketplacePage from './pages/MarketplacePage';
import PrivateRoute from './components/PrivateRoute';
import ProfileDetailPage from './pages/profileDetailPage'
import FavoritesPage from './pages/FavoritesPage'
import MyBeatsPage from './pages/MyBeatsPage'
import { AudioProvider } from "./contexts/AudioContext";
import { AuthProvider } from './contexts/AuthContext';
import { WalletProvider } from './contexts/WalletContext';
import { UserProvider } from './contexts/UserContext';
import React, { useState, useEffect, useContext } from 'react';
import SoundBar from "./components/Soundbar";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AudioContext from "./contexts/AudioContext";


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

// Define your custom theme -- fallback color: 004c3f
const theme = createTheme({
  palette: {
    primary: {
      main: '#025A1F', // Dark green
    },
    secondary: {
      main: '#788732', // Dark green
    },
    thirdary: {
      main: '#16873A', // Dark green
    },
    danger: {
      main: '#A61306', // Red
    },
  },
    background: {
      default: '#303030', // dark gray
    },
    text: {
      primary: '#ffffff', // white
    }
});

// app.js
function App(user) {

  const routes = useRoutes([
    { path: '/', element: <MarketplacePage /> },
    {
      path: '/profile', element: <ProfilePage key={user ? user._id : 'null'} />    },
    { path: '/profile-detail/:userId', element: <ProfileDetailPage /> },
    { path: '/mint-nft/*', element: <MintNFTPage />},
    { path: '/nft/:id', element: <NFTDetailPage /> },
    { path: '/favorites', element: <FavoritesPage /> },
    { path: '/inventory', element: <MyBeatsPage /> },

  ]);

  return routes;
}

function WrappedApp() {
  const [user, setUser] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [nftAudioUrls, setNftAudioUrls] = useState([]);


  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        return;
      }

      if(user) {
      try {
        const response = await fetch('https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com//users/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
        });

        if (response.status === 401) {
          // Token has expired, clear it from local storage and return null
          localStorage.removeItem('access_token');
          return null;
        } else if (!response.ok) {
          throw new Error(response.statusText);
        }

        const userData = await response.json();
        setUser(userData.user);

        const data = await response.json();
        return data.user;
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  }

    fetchUser();
  }, [user]);

  // Get NFT URLs for the nftAudioURLs array in the AudioContext
  useEffect(() => {
    const fetchNfts = async () => {
      try {
        let url = "https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/NFTs/getAllNFTs";
        const response = await fetch(url);
        const data = await response.json();

        setNfts(data);

        // Update the nftAudioUrls array in the AudioContext
        const audioUrls = data.map(nft => nft);
        setNftAudioUrls(audioUrls);
      } catch (error) {
        console.error("Error fetching NFT data:", error);
      }
    };

    fetchNfts();
  }, []);

  return (
    <ThemeProvider theme={theme}>

    <ErrorBoundary>
    <AudioProvider>
    <WalletProvider>
      <AuthProvider>
            <UserProvider>
              <Router>
              <App key={user ? user._id : 'initial'} user={user} />
              <SoundBar nftAudioUrls={nftAudioUrls} /> 
              </Router>
            </UserProvider>
      </AuthProvider>
      </WalletProvider>
      </AudioProvider>
    </ErrorBoundary>
    </ThemeProvider>

  );
}

export default WrappedApp;