import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { CircularProgress, Snackbar } from '@mui/material';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DiscoverIcon from '@mui/icons-material/Explore';
import WindowIcon from '@mui/icons-material/Window';
import MyBeatsIcon from '@mui/icons-material/Headset';
import FavoritesIcon from '@mui/icons-material/Favorite';
import CreateIcon from '@mui/icons-material/Create';
import HistoryIcon from '@mui/icons-material/History';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import defaultProfilePicture from "../images/defaultProfilePicture.png";
import Avatar from '@mui/material/Avatar';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ConnectWallet from '../components/ConnectWallet';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import white from '../images/logos/white.png'; // import your logo image
import { AuthContext } from '../contexts/AuthContext';
import { useWalletContext } from '../contexts/WalletContext';
import axios from 'axios';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    boxShadow: 'none', // Add this line
    borderRight: '1px solid #E8E8E8', // Change #COLOR to the color you want

});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    boxShadow: 'none', // Add this line
    borderRight: '1px solid #E8E8E8', // Change #COLOR to the color you want

});


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
    margin: 0,
    ...theme.mixins.toolbar,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        transition: theme.transitions.create('width'),
        width: '100%',
        boxShadow: 'none', // remove blue outline
    },
    '& .MuiInputBase-input:focus': {
        boxShadow: 'none', // remove blue outline when clicked
    },
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "hideappbar",
})(({ theme, open, hideappbar }: MuiAppBarProps & { open?: boolean; hideappbar?: boolean }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0, 128, 0, 1)", // Greenish color with transparency
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(hideappbar && {
        display: "none",
    }),
    display: "flex",
    flexDirection: "column", // Added this line to make the tabs bar stay under the app bar
}));



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),

        }),
    }),
);

type SidebarProps = {
    hideappbar?: boolean;
    initialDrawerOpen?: boolean;
    onButtonClick?: () => void;
    searchInput: string;
    setSearchInput: (value: string) => void;
    profilePic?: string;
};

export default function MiniDrawer({
    hideappbar,
    initialDrawerOpen,
    onButtonClick,
    searchInput,
    setSearchInput,
    profilePic,
}: SidebarProps) {

    const { dispatch } = useContext(AuthContext);
    const { walletConnected, setWalletConnected, walletAddress, setWalletAddress } = useWalletContext();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [loadingImage, setLoadingImage] = useState(true);
    const [isFetchComplete, setFetchComplete] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [userId, setUserId] = useState(null);

    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = React.useState(initialDrawerOpen);
    const [connectWalletOpen, setConnectWalletOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        //setAuth(false); // Update the authentication state
        dispatch({ type: 'SET_AUTH', payload: false }); // Update the authentication state
        setWalletConnected(false);
        setWalletAddress('');
        // Display a success message
        setSnackbarMessage('Wallet disconnected successfully');
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleConnectWalletOpen = () => {
        setConnectWalletOpen(true);
    };

    const handleConnectWalletClose = () => {
        setConnectWalletOpen(false);
    };


    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const [profilePicUrl, setProfilePicUrl] = useState(null);
    const [showConnectWallet, setShowConnectWallet] = useState(false);

    useEffect(() => {
        async function fetchUserDetails() {
            setIsLoading(true);
            const token = localStorage.getItem('access_token');
            // Add this check to return null if the token is not present
            if (!token) {
                setIsLoading(false);
                return null;
            }

            try {
                if (token) {
                    const response = await fetch('https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/user', {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (response.status === 401) {
                        // Token has expired, clear it from local storage and return null
                        localStorage.removeItem('access_token');
                        localStorage.clear();

                        return null;
                    }
                    setWalletConnected(true);
                    setIsLoading(false);
                    const data = await response.json();
                    setFetchComplete(true);  // Add this line
                    return data.user;
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                setFetchError(true); // Set fetchError to true if there is an error
                return null;
            }
        }

        const getUserDetails = async () => {
            setIsLoading(true);
            const user = await fetchUserDetails();
            setUser(user);
            if (!user?.profilePicture) {
                setProfilePicUrl(defaultProfilePicture);
            } else {
                setProfilePicUrl(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/image/${user.profilePicture.split('/').pop()}`);
            }
            setIsLoading(false);
        };

        getUserDetails();
    }, [setWalletConnected, walletConnected]);

    useEffect(() => {
        console.log('Sidebars walletConnected state changed, current state: ', walletConnected);
    }, [walletConnected]);

    useEffect(() => {
        const img = new Image();
        img.src = profilePicUrl;
        img.onload = () => {
            setLoadingImage(false);
        }
        setUserId(user?._id);
    }, [user, profilePicUrl]);

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={drawerOpen}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            ...(drawerOpen && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid container alignItems="center" spacing={5} sx={{ marginLeft: 8 }}>
                        <Grid item xs>
                            <StyledInputBase
                                hideappbar={hideappbar}
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                disabled={hideappbar ? true : false}
                                placeholder={hideappbar ? '' : 'Search for beats, producers and more'}
                                inputProps={{ 'aria-label': 'search' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            {
                                isLoading ? (
                                    <CircularProgress />
                                ) : walletConnected ? (
                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Link to="/profile">
                                                {
                                                    fetchError
                                                        ? <div>Error loading profile picture</div>
                                                        : !isFetchComplete
                                                            ? <CircularProgress />
                                                            : <Avatar
                                                                key={profilePicUrl}
                                                                src={profilePicUrl || defaultProfilePicture}
                                                                alt="Profile Image"
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    border: '1px solid #17B717',
                                                                }}
                                                            />
                                                }
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                onClick={handleLogout}
                                                sx={{
                                                    backgroundImage: 'linear-gradient(to right, #20961C, #5FC15E)',
                                                    borderColor: '#018701',
                                                    color: '#FFFFFF',
                                                    transition: '0.3s',
                                                    '&:hover': {
                                                        backgroundImage: 'linear-gradient(to right, #5FC15E, #20961C)',
                                                    },
                                                }}
                                            >
                                                Disconnect Wallet
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        onClick={handleConnectWalletOpen}
                                        sx={{
                                            backgroundImage: 'linear-gradient(to right, #20961C, #5FC15E)',
                                            borderColor: '#018701',
                                            color: '#FFFFFF',
                                            transition: '0.3s',
                                            '&:hover': {
                                                backgroundImage: 'linear-gradient(to right, #5FC15E, #20961C)',
                                            },
                                        }}
                                    >                              Connect Wallet
                                    </Button>
                                )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={drawerOpen}
                sx={{
                    '& .MuiDrawer-paper': {
                        background: 'linear-gradient(to right, #A8A8A8, #F0F0F0)', // Linear gradient from left (#E8E8E8) to right (#F0F0F0)
                        boxShadow: 'none', // Removes box-shadow

                    },
                }}
            >
                <DrawerHeader>
                    {drawerOpen && (
                        <img src={white} alt="BlockchainBeats.Ai" style={{ width: '200px', margin: 0, padding: 0 }} />
                    )}
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider />
                <List>
                    {['Discover'].map((text, index) => {
                        const icons = [<DiscoverIcon />, <WindowIcon />, <MyBeatsIcon />, <FavoritesIcon />];
                        const routes = ['/'];
                        return (
                            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                                <Link to={routes[index]}>
                                    <ListItemButton
                                        onClick={() => {
                                            onButtonClick && onButtonClick(); // Add this line
                                        }}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: drawerOpen ? "initial" : "center",
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: drawerOpen ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {icons[index]}
                                        </ListItemIcon>
                                        <ListItemText primary={text} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        );
                    })}
                    <ListItem key="My Beats" disablePadding sx={{ display: 'block' }}>
                        {walletConnected ? (
                            <Link to={'/inventory'}>
                                <ListItemButton
                                    onClick={(e) => {
                                        if (!walletConnected) {
                                            setConnectWalletOpen(true);
                                            e.preventDefault();
                                        } else {
                                            onButtonClick && onButtonClick();
                                        }
                                    }}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: drawerOpen ? "initial" : "center",
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: drawerOpen ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <MyBeatsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"My Beats"} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </Link>
                        ) : (
                            <ListItemButton
                                onClick={(e) => {
                                    setConnectWalletOpen(true);
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: drawerOpen ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: drawerOpen ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <MyBeatsIcon />
                                </ListItemIcon>
                                <ListItemText primary={"My Beats"} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                            </ListItemButton>
                        )}
                    </ListItem>
                    <ListItem key="Favorites" disablePadding sx={{ display: 'block' }}>
                        {walletConnected ? (
                            <Link to={'/favorites'}>
                                <ListItemButton
                                    onClick={(e) => {
                                        if (!walletConnected) {
                                            setConnectWalletOpen(true);
                                            e.preventDefault();
                                        } else {
                                            onButtonClick && onButtonClick();
                                        }
                                    }}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: drawerOpen ? "initial" : "center",
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: drawerOpen ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FavoritesIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Favorites"} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </Link>
                        ) : (
                            <ListItemButton
                                onClick={(e) => {
                                    setConnectWalletOpen(true);
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: drawerOpen ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: drawerOpen ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <FavoritesIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Favorites"} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                            </ListItemButton>
                        )}
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem key="Connect Wallet" disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={handleConnectWalletOpen}
                            sx={{
                                minHeight: 48,
                                justifyContent: drawerOpen ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: drawerOpen ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={walletConnected ? "Wallet Connected" : "Connect Wallet"}
                                sx={{ opacity: drawerOpen ? 1 : 0 }}
                            />
                        </ListItemButton>

                    </ListItem>


                    {['Create'].map((text, index) => {
                        const icons = [<CreateIcon />, <HistoryIcon />, <ProfileIcon />];
                        const routes = ['/mint-nft'];

                        return (
                            <React.Fragment key={text}>
                                <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                                    <Link to={routes[index]}>
                                        <ListItemButton
                                            onClick={() => {
                                                onButtonClick && onButtonClick(); // Add this line
                                            }}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: drawerOpen ? "initial" : "center",
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: drawerOpen ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {icons[index]}
                                            </ListItemIcon>
                                            <ListItemText primary={text} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            </React.Fragment>

                        );
                    })}
                    <ListItem key="Profile" disablePadding sx={{ display: 'block' }}>
                        {walletConnected ? (
                            <Link to={`/profile-detail/${userId}`}>
                                <ListItemButton
                                    onClick={(e) => {
                                        if (!walletConnected) {
                                            setConnectWalletOpen(true);
                                            e.preventDefault();
                                        } else {
                                            onButtonClick && onButtonClick();
                                        }
                                    }}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: drawerOpen ? "initial" : "center",
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: drawerOpen ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ProfileIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Profile"} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                                </ListItemButton>
                            </Link>
                        ) : (
                            <ListItemButton
                                onClick={(e) => {
                                    setConnectWalletOpen(true);
                                }}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: drawerOpen ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: drawerOpen ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ProfileIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Profile"} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                            </ListItemButton>
                        )}
                    </ListItem>
                </List>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
            </Box>
            <Dialog open={connectWalletOpen} onClose={handleConnectWalletClose}>
                <DialogContent>
                    <ConnectWallet onWalletConnected={() => { setShowConnectWallet(false); }} />
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={handleSnackbarClose}>
                            Close
                        </Button>
                    </React.Fragment>
                }
            />
        </Box>
    );
}

export { drawerWidth };