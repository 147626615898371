import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Button, Tab, Tabs, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Sidebar from "../components/sidebar";
import { CircularProgress } from '@mui/material';
import defaultProfilePicture from "../images/defaultProfilePicture.png";
import NftCard from "../components/NftCard";
import AudioContext from "../contexts/AudioContext";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FollowerItem from '../components/FollowerItem';
import FollowButton from '../components/followButton';


const ProfileDetailPage = ({ }) => {
  const [user, setUser] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [openFollowers, setOpenFollowers] = useState(false);
  const [openFollowing, setOpenFollowing] = useState(false);
  const [isFollowingCurrentUser, setIsFollowingCurrentUser] = useState(false);
  const { setNftAudioUrls } = useContext(AudioContext);
  const { userId } = useParams();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const gridSpacing = isLargeScreen ? 4 : 2;

  const fetchCurrentUser = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) return;

      const response = await fetch("https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/user", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setCurrentUser(data.user);
    } catch (error) {
      console.error("Error fetching current user data:", error);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const handleButtonClick = (followerId) => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      return;
    } else {
      handleFollow(followerId);
    }
  };

  const handleFollow = async (followerId) => {
    // Add your logic to handle follow/unfollow action here
    try {
      const token = localStorage.getItem("access_token");
      if (!token) return;
      const response = await fetch(`https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/follow/${followerId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          action: following.some(user => user._id === followerId) ? 'unfollow' : 'follow',
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setCurrentUser(data.user);
        if (following.some(user => user._id === followerId)) {
          setFollowing(following.filter(user => user._id !== followerId));
          localStorage.setItem(`following-${followerId}`, false);
        } else {
          setFollowing([...following, data.followedUser]);
          localStorage.setItem(`following-${followerId}`, true);
        }
      } else {
        throw new Error('Error following or unfollowing user');
      }
    } catch (error) {
      console.error("Error following or unfollowing user:", error);
    }
  };


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchUserAndNfts = async () => {
    try {
      const response = await fetch(
        `https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/users/${userId}`
      );
      const data = await response.json();
      setUser(data.user);
      setFollowers(data.user.followers);
      setFollowing(data.user.following);
      if (currentUser) {
        setIsFollowingCurrentUser(data.user.following.some(followed => followed._id === currentUser._id));
      } else {
        setIsFollowingCurrentUser(false);
      }

      // Fetch NFT details
      const nftPromises = data.user.nfts.map((nft) =>
        fetch(
          `https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/nfts/id/${nft._id}`
        )
      );
      const nftResponses = await Promise.all(nftPromises);
      const nftData = await Promise.all(
        nftResponses.map((nftResponse) => nftResponse.json())
      );
      setUser((prevUser) => ({ ...prevUser, nfts: nftData }));

    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    fetchUserAndNfts();
  }, [userId, currentUser]);

  useEffect(() => {
    const fetchNfts = async () => {
      try {
        let url = "https://blockchainbeats-backend-e308c28c1ca8.herokuapp.com/NFTs/getAllNFTs";
        const response = await fetch(url);
        const data = await response.json();

        setNfts(data);

        // Update the nftAudioUrls array in the AudioContext
        const audioUrls = data.map(nft => nft);
        setNftAudioUrls(audioUrls);
      } catch (error) {
        console.error("Error fetching NFT data:", error);
      }
    };

    fetchNfts();
  }, []);


  if (!user) {
    return <div>Loading...
      <CircularProgress />
    </div>;
  }

  return (
    <Box sx={{ display: 'flex', background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)',}}>
<Sidebar hideappbar="true" />
      <Grid container spacing={4} justifyContent="center">
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, marginTop: 16 }}>
                <Typography variant="h4">
                  {user.username}
                </Typography>
                {isFollowingCurrentUser && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: 1,
                      borderRadius: 1,
                      paddingX: 1,
                      paddingY: 0.5,
                      backgroundColor: 'grey.400',
                    }}
                  >
                    <Typography variant="subtitle2">
                      Follows You
      </Typography>
                  </Box>
                )}
              </Box>

              <img src={user.profilePicture || defaultProfilePicture} alt={user.username} width="200"
                style={{
                  borderRadius: '50%',
                  border: '2px solid #048F31',
                  objectFit: 'cover',
                  height: '200px',
                  width: '200px'
                }} />
              <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>{user.bio}</Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button variant="contained" color="thirdary" onClick={() => setOpenFollowers(true)} sx={{ color: 'white' }}>
                  {user.followers?.length} Followers
                </Button>
                <Button variant="contained" color="thirdary" onClick={() => setOpenFollowing(true)} sx={{ color: 'white' }}>
                  {user.following?.length} Following
                </Button>

              </Box>
              <Box sx={{ marginTop: 2 }}>
                <FollowButton
                  buttonWidth="280px"
                  producerId={user._id}
                  currentUser={currentUser}
                  setCurrentUser={setCurrentUser}
                  onFollowStatusChange={(newFollowingStatus) => {
                    if (newFollowingStatus) {
                      setUser((prevUser) => ({ ...prevUser, followers: [...prevUser.followers, currentUser] }));
                      setFollowers((prevFollowers) => [...prevFollowers, currentUser]);
                    } else {
                      setUser((prevUser) => ({ ...prevUser, followers: prevUser.followers.filter((follower) => follower._id !== currentUser._id) }));
                      setFollowers((prevFollowers) => prevFollowers.filter((follower) => follower._id !== currentUser._id));
                    }
                  }}

                />

              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
  <Box sx={{ width: '100%', background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)' }}>
    <Tabs value={tabValue} onChange={handleTabChange} centered>
      <Tab label="Minted NFTs" />
      <Tab label="Owned NFTs" />
    </Tabs>
    <TabPanel value={tabValue} index={0} gridSpacing={4}>
      {/* Render minted NFTs */}
      {nfts
        .filter(nft => nft.Issuer === userId) // Filter NFTs by issuer ID
        .map((nft, index) => (
          <Grid item xs={12} sm={6} md={4} key={nft._id}>
            <NftCard nft={nft} nfts={nfts} nftIndex={index} />
          </Grid>
        ))}
    </TabPanel>
    <TabPanel value={tabValue} index={1} gridSpacing={4}>
      {/* Render owned NFTs */}
      {user.nfts // Filter NFTs by owner ID
        .map((nft, index) => (
          <Grid item xs={12} sm={6} md={4} key={nft._id}>
            <NftCard nft={nft} nfts={user.nfts} nftIndex={index} />
          </Grid>
        ))}
    </TabPanel>
  </Box>
</Grid>


          {/* Followers Dialog */}
          <Dialog open={openFollowers} onClose={() => setOpenFollowers(false)}>
            <DialogTitle>Followers</DialogTitle>
            <DialogContent>
              <Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
                {followers?.length > 0 ? (
                  followers.map((follower, index) => (
                    <FollowerItem
                      key={`follower-${follower._id}-${index}`}
                      user={follower}
                      currentUser={currentUser}
                      onFollow={(user) => handleButtonClick(user._id)}
                      closePopup={() => setOpenFollowers(false)}
                    />
                  ))
                ) : (
                    <Typography>User does not have any followers</Typography>
                  )}
              </Box>
            </DialogContent>
          </Dialog>

          {/* Following Dialog */}
          <Dialog open={openFollowing} onClose={() => setOpenFollowing(false)}>
            <DialogTitle>Following</DialogTitle>
            <DialogContent>
              <Box sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
                {following?.length > 0 ? (
                  following.map((followed, index) => (
                    <FollowerItem
                      key={`following-${followed._id}-${index}`}
                      user={followed}
                      currentUser={currentUser}
                      onFollow={(user) => handleButtonClick(user._id)}
                      closePopup={() => setOpenFollowing(false)}
                    />
                  ))
                ) : (
                    <Typography>User is not following any accounts</Typography>
                  )}
              </Box>
            </DialogContent>
          </Dialog>

        </Grid>
      </Grid>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, gridSpacing, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container sx={{ p: 3 }} justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            <Grid container justifyContent="center" spacing={gridSpacing}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}


export default ProfileDetailPage;